<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h3 class="">Importar planilha</h3>
        </div>
      </div>
      <div class="col-sm-6">
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-body">
        <h4 v-if="loading" class="">Enviando...</h4>
        <div class="input-group mb-3">
          <div class="custom-file" style="max-width: 500px; margin: 0 auto">
            <input @input="onFile" type="file" class="custom-file-input" :disabled="loading">
            <label class="custom-file-label" for="inputGroupFile01">Escolher arquivo</label>
          </div>
        </div>

        <template v-if="errors.length">
          <h4 class="">Erros!</h4>

          <ul>
            <li v-for="(err, i) in errors" :key="i">{{err}}</li>
          </ul>
        </template>
      </div>
    </div>
  </Layout>
</template>

<script>
import stripHtml from "string-strip-html";
import Layout from "../layouts/main";
import appConfig from "@/app.config";

import * as api from "@/api";

export default {
  stripHtml,

  name: "ImportSpreadsheet",

  page: {
    title: "Gerenciamento de Perguntas",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
  },

  data() {
    return {
      loading: false,
      errors: [],
    };
  },

  methods: {
    onFile(ev) {
      this.loading = true
      this.errors = []

      if (!ev.target.files.length) { return }

      api
        .uploadSpreadsheet(ev.target.files[0])
        .then(() => {
          this.$swal("Sucesso", "Questões importadas", "success");
        })
        .catch((err) => {
          if (err.response && err.response.data.errors.length) {
            this.errors = err.response.data.errors
            return
          }

          this.$swal("Opa", "Algo deu errado salvando o áudio", "warning");
        })
        .finally(() => this.loading = false);
    },

  },
};
</script>
